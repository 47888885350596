import './App.css';
import {
  Routes,
  Route,
} from "react-router-dom";
import { FactionView } from './components/views/Faction/Factions/Faction';
import { FactionsView } from './components/views/Faction/Factions/Factions';
import { Home } from './components/views/Home';
import { TeamView } from './components/views/Team/Team';
import NavBar from './components/views/NavBar';
import { Footer } from './components/views/Footer';
import { Rules } from './components/views/Rules';
import { Contact } from './components/views/Contact';
import { Impressum } from './components/views/Imresspum';
import { PlayView } from './components/views/Play';
import { LeaderboardView } from './components/Leaderboard';

export const server = "https://api.voidroleplay.de";

const App: React.FC = () => {
  return (
    <div>
      <NavBar />
      <Routes>
        <Route path="/factions/:faction" element={< FactionView />} />
        <Route path='/factions' element={< FactionsView />} />
        <Route path='/team' element={< TeamView />} />
        <Route path='/' element={< Home />} />
        <Route path='/play' element={<PlayView />} />
        <Route path='/rules' element={< Rules />} />
        <Route path='/contact' element={< Contact />} />
        <Route path='/impressum' element={< Impressum />} />
        <Route path='/leaderboard/:type' element={< LeaderboardView />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;