import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { server } from "../../App";
import Logo from "../../logo.png";

const NavBar = () => {
    const [factions, setFactions] = useState<any[]>([]);
    const [menuOpen, setMenuOpen] = useState(false);
    const [leaderboards, setLeaderboards] = useState<any[]>([]);
    const [dropdownOpen, setDropdownOpen] = useState<number | null>(null);

    useEffect(() => {
        const load = async () => {
            let response = await fetch(server + '/faction/get', {
                method: 'GET',
                mode: 'cors',
            });
            const factionsData = await response.json();
            setFactions(factionsData);


            response = await fetch(server + '/leaderboards', {
                method: 'GET',
                mode: 'cors',
            });
            const leaderboardData = await response.json();
            console.log(leaderboardData);
            setLeaderboards(leaderboardData);
        };

        load();
    }, []);

    const toggleDropdown = (dropdownId: number | null) => {
        setDropdownOpen(dropdownOpen === dropdownId ? null : dropdownId);
    };

    return (
        <nav className="bg-transparent fixed top-0 w-full z-50">
            <div className="max-w-screen-xl mx-auto p-4 flex items-center justify-between">
                <NavLink to="/" className="flex items-center space-x-3">
                    <img src={Logo} className="h-8" alt="Logo" />
                    <span className="text-2xl font-semibold text-gray-800 dark:text-white">Void Roleplay</span>
                </NavLink>
                <div className="md:hidden">
                    <button
                        onClick={() => setMenuOpen(!menuOpen)}
                        className="p-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:focus:ring-gray-600"
                        aria-controls="navbar-cta"
                        aria-expanded={menuOpen}
                    >
                        <span className="sr-only">Open main menu</span>
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                        </svg>
                    </button>
                </div>
                <div className={`w-full md:flex md:items-center md:w-auto ${menuOpen ? "block" : "hidden"}`} id="navbar-cta">
                    <ul className="flex flex-col md:flex-row md:space-x-8 mt-4 md:mt-0 p-4 md:p-0 bg-white md:bg-transparent rounded-lg md:rounded-none shadow-md md:shadow-none dark:bg-gray-800">
                        <li>
                            <NavLink to="/" className={({ isActive }) => (isActive ? "block py-2 px-3 text-blue-700 md:text-blue-500" : "block py-2 px-3 text-gray-900 hover:text-blue-700 dark:text-white dark:hover:text-blue-500")}>Start</NavLink>
                        </li>
                        <li className="relative group">
                            <button onClick={() => toggleDropdown(1)} className="block py-2 px-3 text-gray-900 hover:text-blue-700 dark:text-white dark:hover:text-blue-500">Hilfe</button>
                            {dropdownOpen === 1 && (
                                <div className="absolute left-0 mt-2 w-40 bg-white rounded-md shadow-lg dark:bg-gray-800 z-10">
                                    <ul className="py-1">
                                        <li>
                                            <Link to="/rules" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Regeln</Link>
                                        </li>
                                        <li>
                                            <Link to="/contact" className="block px-4 py-2 text-gray-700 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Kontakt</Link>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </li>
                        {factions.length > 0 && (
                            <li className="relative group">
                                <button onClick={() => toggleDropdown(2)} className="block py-2 px-3 text-gray-900 hover:text-blue-700 dark:text-white dark:hover:text-blue-500">Fraktionen</button>
                                {dropdownOpen === 2 && (
                                    <div className="absolute left-0 mt-2 w-56 bg-white rounded-md shadow-lg dark:bg-gray-800 z-10">
                                        <ul className="py-1">
                                            {factions.map((faction) => (
                                                <li key={faction.id}>
                                                    <Link to={`/factions/${faction.name.toLowerCase()}`} className="block px-4 py-2 text-gray-700 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">{faction.name}</Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </li>
                        )}

                    {leaderboards.length > 0 && (
                            <li className="relative group">
                                <button onClick={() => toggleDropdown(3)} className="block py-2 px-3 text-gray-900 hover:text-blue-700 dark:text-white dark:hover:text-blue-500">Leaderboards</button>
                                {dropdownOpen === 3 && (
                                    <div className="absolute left-0 mt-2 w-56 bg-white rounded-md shadow-lg dark:bg-gray-800 z-10">
                                        <ul className="py-1">
                                            {leaderboards.map((leaderboard, index) => (
                                                <li key={index}>
                                                    <Link to={`/leaderboard/${leaderboard.url}`} className="block px-4 py-2 text-gray-700 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">{leaderboard.display}</Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default NavBar;
