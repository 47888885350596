import { Link } from "react-router-dom";

export const Footer = () => {
    return (
        <footer className="bg-gray-300 dark:bg-gray-800 flex flex-col sm:flex-row py-6 w-full border-t">
    <p className="text-xs text-center sm:text-left text-gray-600 dark:text-gray-400 px-4 md:px-6 mb-4 sm:mb-0">
        Void Roleplay steht nicht in Verbindung zu Mojang, Minecraft oder Microsoft.
    </p>
    <nav className="flex flex-wrap justify-center sm:justify-end gap-4 sm:gap-6 sm:ml-auto sm:mr-4">
        <Link to="https://forum.voidroleplay.de/index.php?legal-notice/" className="text-xs hover:underline underline-offset-2 text-gray-600 dark:text-gray-400">
            Impressum
        </Link>
        <Link to={"https://forum.voidroleplay.de/index.php?datenschutzerklaerung%2F="} className="text-xs hover:underline underline-offset-2 text-gray-600 dark:text-gray-400">
            Datenschutzerklärung
        </Link>
        <Link to="/contact" className="text-xs hover:underline underline-offset-2 text-gray-600 dark:text-gray-400">
            Kontakt
        </Link>
        <Link to="https://forum.voidroleplay.de" className="text-xs hover:underline underline-offset-2 text-gray-600 dark:text-gray-400">
            Forum
        </Link>
        <Link to="https://shop.voidroleplay.de" className="text-xs hover:underline underline-offset-2 text-gray-600 dark:text-gray-400">
            Shop
        </Link>
        <Link to="/team" className="text-xs hover:underline underline-offset-2 text-gray-600 dark:text-gray-400">
            Team
        </Link>
        <Link to="https://discord.me/voidrp" className="text-xs hover:underline underline-offset-2 text-gray-600 dark:text-gray-400">
            Discord
        </Link>
    </nav>
</footer>

    );
};
