import { server } from "../../App";

export const getUserData = async () => {
  try {
    var user = localStorage.getItem('user');
    const response = await fetch(server + '/account/user/getuser', {
      method: 'GET',
      headers: {
        Authorization: user ? user : '',
      },
      mode: 'cors',
    });

    if (!response.ok) {
      return null;
    }

    const userData = await response.json();

    if (isUserValid(userData)) {
      return userData;
    } else {
      throw new Error("Invalid user data");
    }
  } catch (error) {
    console.error("Failed to fetch user data", error);
    return null;
  }
};


export const formatNumber = (num: number) => {
  if (num === undefined || num === null) {
    return num;
  }
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}

export const formatTime = (datetimeStr: string) => {
  let datetime = new Date(datetimeStr);
  let datePortion = datetime.toLocaleString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
  let timePortion = datetime.toLocaleString('de-DE', { hour: '2-digit', minute: '2-digit', hour12: false });
  let formatted = `${datePortion} | ${timePortion}`;

  return formatted;
}

export const isUserValid = (user: any): boolean => {
  if (!user) {
    return false;
  }

  if (user === null || user === undefined) {
    return false;
  }

  if (user === "null" || user === "undefined") {
    return false;
  }
  if (user === "User not found") {
    return false;
  }
  return true;
}