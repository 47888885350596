import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { server } from "../../../App";
import { CenteredLoader } from "../Utils";
import { Avatar } from "@mui/joy";

import GroupsIcon from '@mui/icons-material/Groups';

export const TeamView = () => {
    const navigate = useNavigate();
    const [team, setTeam] = useState<any>();
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        const load = async () => {
            setLoading(true);
            const response = await fetch(`${server}/team`, {
                method: 'GET',
                mode: 'cors',
            });
            const companyData = await response.json();

            console.log(companyData);

            if (companyData === null) {
                navigate('/');
            }

            setTeam(companyData);
            setLoading(false);
        }

        load();
    }, [navigate]);
    return (
        <>
            {!isLoading ? (
                <>
                    {team ? (
                        <>
        <div className="flex flex-col min-h-screen bg-gray-900 text-gray-200">
            <div className="mt-10 w-full max-w-2xl mx-auto px-4 py-6 space-y-8">
                <div className="flex items-center gap-4">
                                        <Avatar className="h-24 w-24">
                                            <GroupsIcon />
                                        </Avatar>
                                        <div className="grid gap-1">
                                            <h1 className="text-2xl font-bold dark:text-gray-200">Serverteam</h1>
                                        </div>
                                    </div>
                                    <div className="space-y-2">
                                        <div className="grid gap-1">
                                            {team &&
                                                team.map((player: any) => (
                                                    <>
                                                        <Player
                                                            username={player.player_name}
                                                            rank={player.rang}
                                                            uuid={player.uuid}
                                                        />
                                                    </>
                                                )
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
                                <CenteredLoader />
                            </div>
                        </>
                    )}
                </>
            ) : (
                <>
                    <div className="flex items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
                        <CenteredLoader />
                    </div>
                </>
            )}
        </>
    )
}

const Player = ({ username, uuid, rank }: any) => {
    return (
        <div className="flex gap-4 items-start rounded-lg bg-white dark:bg-gray-800 shadow-md p-4 transform hover:scale-105 transition-transform">
            <Avatar className="w-14 h-14 border">
                <img
                    src={`https://crafatar.com/avatars/${uuid}`}
                    alt={`Avatar of ${uuid}`}
                />
            </Avatar>
            <div className="grid gap-1">
                <h3 className="font-semibold dark:text-gray-200">{username}</h3>
                <p className="text-sm text-gray-500 dark:text-gray-400">{`${rank}`}</p>
            </div>
        </div>
    )
}