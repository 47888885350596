import { Link } from "react-router-dom";

export const PlayView = () => {
    return (
        <div className="bg-gray-900 min-h-screen flex items-center justify-center px-4">
            <div className="bg-gray-800 p-8 rounded-lg shadow-xl text-center space-y-6 max-w-lg w-full">
                <div className="bg-gray-700 bg-opacity-75 py-4 px-8 rounded-md mb-6">
                    <h1 className="text-4xl font-extrabold text-yellow-400">
                        So legst du los
                    </h1>
                </div>
                <p className="text-lg text-gray-300">
                    Um beizutreten, öffne Minecraft und folge diesen Schritten:
                </p>
                <ol className="text-left text-gray-300 space-y-2">
                    <li className="flex items-start">
                        <span className="mr-2 text-yellow-400 font-bold">1.</span>
                        Starte Minecraft und wähle "Multiplayer" aus dem Hauptmenü.
                    </li>
                    <li className="flex items-start">
                        <span className="mr-2 text-yellow-400 font-bold">2.</span>
                        Klicke auf "Server hinzufügen" und gib "Void Roleplay" als Servernamen ein.
                    </li>
                    <li className="flex items-start">
                        <span className="mr-2 text-yellow-400 font-bold">3.</span>
                        Als Serveradresse verwende <span className="font-mono ml-1">voidroleplay.de</span>.
                    </li>
                    <li className="flex items-start">
                        <span className="mr-2 text-yellow-400 font-bold">4.</span>
                        Klicke auf "Fertig" und dann zweimal auf den Server, um dich zu verbinden.
                    </li>
                    <li className="flex items-start">
                        <span className="mr-2 text-yellow-400 font-bold">5.</span>
                        Tritt nun noch unserem <Link to="https://discord.gg/XzqgeZ4TAU" className="text-orange-500 underline">Discord</Link> bei.
                    </li>
                </ol>
                <p className="text-lg text-gray-300">
                    Viel Spaß beim Spielen auf Void Roleplay!
                </p>
            </div>
        </div>
    );
};
