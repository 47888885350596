import { useState, useEffect } from "react";
import { server } from "../../App";
import { CenteredLoader } from "./Utils";
import Search from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Input } from "@mui/joy";

export const Rules = () => {
    const [rules, setRules] = useState<any[]>([]);
    const [isLoading, setLoading] = useState(false);
    const [types, setTypes] = useState<string[]>([]);
    const [areas, setAreas] = useState<string[]>([]);
    const [expandedAreas, setExpandedAreas] = useState<{ [key: string]: boolean }>({});
    const [expandedTypes, setExpandedTypes] = useState<{ [key: string]: boolean }>({});
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        const load = async () => {
            setLoading(true);

            try {
                const response = await fetch(`${server}/rules`, {
                    method: 'GET',
                    mode: 'cors',
                });

                const rulesData = await response.json();

                // Sort the rulesData based on the order field
                rulesData.sort((a: any, b: any) => a.order - b.order);

                const newTypes = rulesData.filter((rule: any) => rule.isType).map((rule: any) => rule.type);
                const newAreas = rulesData.filter((rule: any) => rule.isArea).map((rule: any) => rule.area);

                setRules(rulesData);
                setTypes(newTypes);
                setAreas(newAreas);

                // Set all areas and types to be expanded by default
                const initialExpandedAreas = newAreas.reduce((acc: any, area: any) => ({ ...acc, [area]: true }), {});
                const initialExpandedTypes = newTypes.reduce((acc: any, type: any) => ({ ...acc, [type]: true }), {});

                setExpandedAreas(initialExpandedAreas);
                setExpandedTypes(initialExpandedTypes);

            } catch (e) {
                console.error(e);  // Handle any errors here
            }

            setLoading(false);

        }

        load();
    }, []);

    const handleAreaToggle = (area: string) => {
        setExpandedAreas(prevState => ({ ...prevState, [area]: !prevState[area] }));
    };

    const handleTypeToggle = (type: string) => {
        setExpandedTypes(prevState => ({ ...prevState, [type]: !prevState[type] }));
    };

    const filteredRules = rules.filter(rule => 
        rule.rule?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
            {rules.length > 0 && !isLoading ? (
                <div className="flex flex-col min-h-screen bg-gray-900 text-gray-200">
                    <main className="flex-1">
                        <section className="w-full py-12 md:py-24 lg:py-32">
                            <div className="container px-4 md:px-6 mx-auto">
                                <div className="flex flex-col items-center space-y-4 text-center">
                                    <div className="space-y-2">
                                        <h1 className="text-4xl font-extrabold tracking-tighter sm:text-5xl md:text-6xl lg:text-7xl">
                                            Regelwerk
                                        </h1>
                                    </div>
                                    <div className="w-full max-w-lg mx-auto">
                                        <form className="mt-5 relative flex items-center">
                                            <input
                                                className="pl-4 py-2 w-full border border-gray-600 rounded-l-full bg-gray-800 text-gray-200 placeholder-gray-400 focus:ring-2 focus:ring-blue-500"
                                                placeholder="Regel suchen..."
                                                type="search"
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                            />
                                            <button
                                                className="px-4 py-2 bg-blue-500 text-white rounded-r-full hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                                onClick={(e) => e.preventDefault()}
                                            >
                                                <Search />
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="w-full py-12">
                            <div className="container px-4 md:px-6 mx-auto">
                                <h1 className="mb-6">Das Void Roleplay Team behält sich vor, Spieler vom Projekt auszuschließen.</h1>
                                {areas && areas.map((area, areaIndex) => (
                                    <div key={areaIndex} className="mb-8 bg-gray-800 p-6 rounded-lg shadow-md">
                                        <div className="flex items-center justify-between cursor-pointer" onClick={() => handleAreaToggle(area)}>
                                            <h2 className="text-2xl font-semibold">{area}</h2>
                                            {expandedAreas[area] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </div>
                                        {expandedAreas[area] && (
                                            <div className="pl-4 mt-4">
                                                {types && types.map((type, typeIndex) => (
                                                    rules.some(rule => rule.type === type && rule.area === area) && (
                                                        <div key={typeIndex} className="mb-4">
                                                            <div className="flex items-center justify-between cursor-pointer" onClick={() => handleTypeToggle(type)}>
                                                                <h3 className="text-lg font-semibold text-gray-400">{type}</h3>
                                                                {expandedTypes[type] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                            </div>
                                                            {expandedTypes[type] && (
                                                                <div className="pl-4 mt-2">
                                                                    {filteredRules.map((rule, ruleIndex) => (
                                                                        rule.type === type && rule.area === area && !rule.isType && (
                                                                            <div className="flex flex-col space-y-2" key={ruleIndex}>
                                                                                <p className="text-sm text-gray-400">{rule.rule}</p>
                                                                            </div>
                                                                        )
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    )
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </section>
                    </main>
                </div>
            ) : (
                <div className="flex items-center justify-center h-screen bg-gray-900">
                    <CenteredLoader />
                </div>
            )}
        </>
    );
}
