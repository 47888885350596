import { Leaderboard } from '@mui/icons-material';
import { Avatar } from '@mui/joy';
import React, { useState, useEffect } from 'react';
import { server } from '../App';
import { CenteredLoader } from './views/Utils';
import { useParams } from 'react-router-dom';

const ITEMS_PER_PAGE = 20;

export const LeaderboardView = () => {
    const [data, setData] = useState<any[]>([]);
    const [leaderBoardInfo, setLeaderboardInfo] = useState<any>({});
    const [isLoading, setLoading] = useState(false);
    const { type } = useParams();

    useEffect(() => {
        const load = async () => {
            setLoading(true);
            let response = await fetch(`${server}/leaderboard/${type}`, {
                method: 'GET',
                mode: 'cors',
            });
            const companyData = await response.json();

            setData(prevData => [...prevData, ...companyData]);

            response = await fetch(`${server}/leaderboard-info/${type}`, {
                method: 'GET',
                mode: 'cors',
            })

            setLeaderboardInfo(await response.json());
            setLoading(false);
        }

        load();
    }, []);

    return (
        <div className="flex flex-col min-h-screen bg-gray-900 text-gray-200">
            <div className="mt-10 w-full max-w-2xl mx-auto px-4 py-6 space-y-8">
                <div className="flex items-center gap-4">
                    <Avatar className="h-24 w-24">
                        <Leaderboard />
                    </Avatar>
                    <div className="grid gap-1">
                        <h1 className="text-2xl font-bold dark:text-gray-200">Leaderboard - {leaderBoardInfo.display}</h1>
                    </div>
                </div>
                <div className="space-y-2">
                    <h2 className="text-xl font-semibold dark:text-gray-300">Top 10</h2>
                    {data.slice(0, 10).map((player, index) => (
                        <Player
                            key={player.uuid}
                            username={player.player_name}
                            rank={index + 1}
                            uuid={player.uuid}
                            level={player.level}
                            type={leaderBoardInfo.type}
                        />
                    ))}
                    <h2 className="text-xl font-semibold dark:text-gray-300">Top 50</h2>
                    {data.slice(10, 50).map((player, index) => (
                        <Player
                            key={player.uuid}
                            username={player.player_name}
                            rank={index + 11}
                            uuid={player.uuid}
                            level={player.level}
                            type={leaderBoardInfo.type}
                        />
                    ))}
                    <h2 className="text-xl font-semibold dark:text-gray-300">Top 100</h2>
                    {data.slice(50, 100).map((player, index) => (
                        <Player
                            key={player.uuid}
                            username={player.player_name}
                            rank={index + 51}
                            uuid={player.uuid}
                            level={player.level}
                            type={leaderBoardInfo.type}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};


const Player = ({ rank, username, uuid, level, type }: any) => {
    return (
        <div className="flex gap-4 items-center rounded-lg bg-gray-800 shadow-md p-4 transform hover:scale-105 transition-transform">
            <Avatar className="w-14 h-14 border hover:scale-110 transition-transform">
                <img
                    src={`https://crafatar.com/avatars/${uuid}`}
                    alt={`Avatar of ${uuid}`}
                />
            </Avatar>

            <div className="grid gap-1">
                <h3 className="font-semibold dark:text-gray-200">
                    <a className={`font-bold ${rank === 1 ? 'text-yellow-300' : rank === 2 ? 'text-yellow-500' : rank === 3 ? 'text-yellow-600' : 'text-orange-500'}`}>
                        #{rank}
                    </a>
                    <a className='ml-2'>{username}</a>
                    <span className="ml-2 bg-yellow-500 text-white text-xs font-bold py-1 px-2 rounded-full">{`${type} ${level}`}</span>
                </h3>
            </div>
        </div>
    )
}